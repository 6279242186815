$(function () {
  //////ナビゲーション
  $(".menu_trigger").on("click", function () {
    $(this).toggleClass("active");
    $(".nav_contents").slideToggle();
  });

  $(".nav_contents a").on("click", function (event) {
    if (window.innerWidth <= 1200) {
      event.preventDefault();
      var target = $(this).attr("href");
      $(".menu_trigger").click();
      setTimeout(function () {
        window.location.href = target;
      }, 300);
    }
  });

  $(window).on("resize", function () {
    if ($(".nav_contents").css("display") === "none") {
      $(".nav_contents").attr("style", "");
    }
  });

  //////ナビゲーション(sub pc)
  $(document).ready(function () {
    if (window.matchMedia("(min-width: 1200px)").matches) {
      $(".nav_item li").hover(function () {
        $(this).children(".nav_item_sub__inner").stop().fadeToggle("fast");
      });
    } else {
      $(".nav_item li").click(function () {
        $(this)
          .children(".nav_item_sub__inner")
          .stop()
          .slideToggle(function () {
            if ($(this).is(":visible")) {
              $(this).parent("li").addClass("open");
            } else {
              $(this).parent("li").removeClass("open");
            }
          });

        $(".nav_item li")
          .not($(this))
          .children(".nav_item_sub__inner")
          .slideUp()
          .parent("li")
          .removeClass("open");
      });
    }
  });
  //////ヘッダー変更
  $(window).scroll(function () {
    if ($(window).scrollTop() > 50) {
      $("header").addClass("header_fixed");
    } else {
      $("header").removeClass("header_fixed");
    }
  });
  //////ページトップ
  var topBtn = $("#page-top");
  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 650) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function () {
    $("body,html").animate(
      {
        scrollTop: 0,
      },
      1000
    );
    return false;
  });
  //////スムーススクロール
  $('a[href^="#"]').click(function () {
    var speed = 1500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
  //////telリンク
  var ua = navigator.userAgent.toLowerCase();
  var isMobile = /iphone/.test(ua) || /android(.+)?mobile/.test(ua);

  if (!isMobile) {
    $('a[href^="tel:"]').on("click", function (e) {
      e.preventDefault();
    });
  }
  //////画像切り替え
  $(function () {
    var width = $(window).width();
    if (width < 767) {
      $("img").each(function () {
        $(this).attr("src", $(this).attr("src").replace("_pc", "_sp"));
      });
    }
  });
  // 表示させるwidthを指定
  var ua = navigator.userAgent;
  var viewport = document.querySelector("meta[name=viewport]");
  if (
    ua.indexOf("iPhone") > 0 ||
    ua.indexOf("iPod") > 0 ||
    (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0) ||
    ua.indexOf("iPad") > 0 ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1) || // iPadデバイスを追加
    (ua.indexOf("Android") > 0 && ua.indexOf("Tablet") > 0) // Androidタブレットデバイスを追加
  ) {
    viewport.setAttribute("content", "width=device-width,initial-scale=1");
  } else {
    viewport.setAttribute("content", "width=1550");
  }
});
// modal
$(document).ready(function () {
  $(".js-modal-open").on("click", function (e) {
    e.preventDefault();
    const modalId = $(this).data("target");
    if (modalId) {
      $(`#${modalId}`).fadeIn();

      // 現在のスクロール位置を保存し、背景を固定
      let scrollPosition = $(window).scrollTop();
      $("body")
        .addClass("fixed")
        .css({ top: -scrollPosition + "px" })
        .data("scrollPosition", scrollPosition);
    }
  });

  $(".js-modal-close").on("click", function (e) {
    e.preventDefault();

    $(this).closest(".c-modal").fadeOut();

    let scrollPosition = $("body").data("scrollPosition") || 0;
    $("body").removeClass("fixed").css({ top: "" });
    $(window).scrollTop(scrollPosition);
  });
});
//vhの取得
function setFullHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

window.addEventListener("resize", setFullHeight);
window.addEventListener("load", setFullHeight);

//////アニメーション
$(window).scroll(function () {
  $(".js_anime").each(function () {
    var pos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > pos - windowHeight + 200) {
      $(this).addClass("scroll");
    }
  });
});

// アコーディオン
$(function () {
  $(".c-ac_list dt").click(function () {
    $(this).next(".c-ac_list dd").slideToggle();
    $(this).toggleClass("open");
    $(".c-ac_list dt").not($(this)).next(".c-ac_list dd").slideUp();
    $(".c-ac_list dt").not($(this)).removeClass("open");
  });
});
//////スライダー KV
document.addEventListener("DOMContentLoaded", function () {
  var swiper01 = new Swiper(".swiper01", {
    loop: true,
    effect: "fade",
    speed: 2000,
    // 自動再生
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
  });
  var swiper02 = new Swiper(".swiper02", {
    loop: true,
    loopedSlides: 6,
    slidesPerView: "auto",
    speed: 8000,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
  });
  // データで見る山瀬青果
  var swiper03 = new Swiper(".swiper03", {
    loop: true, // ループ
    speed: 2000,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    centeredSlides: true,
    slidesPerView: 1.5,
    spaceBetween: 15,
    breakpoints: {
      640: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });
});

//////WP用 NEWS一覧
$(function () {
  $(".post_nav li a").each(function () {
    var $href = $(this).attr("href");

    if (location.href.match($href)) {
      $(this).parent().addClass("active");
    } else {
      $(this).parent().removeClass("active");
    }
  });
});
